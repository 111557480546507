<template>
    <div class="config">
        <div>售后反馈详情</div>
        <div>{{form.title}}</div>
        <div>
            <div>{{form.status_text}}</div>
            <div>{{form.createtime}}</div>
        </div>
        <div>{{form.content}}</div>
        <div>
            <div v-for="(item,index) in form.images" :key="index">
                <img :src="item">
            </div>
        </div>
        <div @click="close"><  返回上一级</div>
    </div>
</template>

<script>
import store from "../store";

export default {
    name: "feedbackDetails",
    data() {
        return {
            form: {},
        }
    },
    mounted() {
        this.form = this.$route.query
        // this.onList();
    },
    methods: {
        /**
         * 返回上一级
         * */
        close() {
            this.$router.push({
                name: 'feedback',
            })
        }
        // onList() {
        //     this.$axios.post('api/setting/config_info', {}).then(res => {
        //         this.form = res.data.data ||  {};
        //         this.$store.commit('setHospital', this.form);
        //     })
        // },
    },
}
</script>

<style lang="scss" scoped>
.config {
    height: 8rem;
    padding: 0.24rem 0.34rem;
    position: relative;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    >div:nth-child(2) {
        font-size: 0.16rem;
        font-weight: 500;
        color: #3D4154;
        margin-top: 0.29rem;
        margin-bottom: 0.12rem;
    }
    >div:nth-child(3) {
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.12rem;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #F6F6F6;
        margin-bottom: 0.07rem;
        >div:nth-child(1) {
            width: 0.51rem;
            height: 0.2rem;
            background: rgba(150,200,246,0.05);
            border-radius: 0.02rem;
            border: 0.01rem solid #4B83F0;
            font-size: 0.11rem;
            font-weight: 400;
            color: #4B83F0;
            line-height: 0.2rem;
            margin-right: 0.05rem;
        }
    }
    >div:nth-child(4) {
        font-size: 0.14rem;
        font-weight: 400;
        color: #666666;
        line-height: 0.21rem;
        margin-bottom: 0.28rem;
    }
    >div:nth-child(5) {
        display: flex;
        img {
            display: block;
           width: 0.81rem;
            height: 0.81rem;
            margin-right: 0.07rem;
        }
    }
    >div:nth-child(6) {
        width: 1.62rem;
        height: 0.52rem;
        border-radius: 0.04rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #4B83F0;
        line-height: 0.52rem;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0.3rem;
        right: 0.28rem;
        border: 0.01rem solid #4B83F0;
    }
}
</style>
